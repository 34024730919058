
import action from "@/actions.js"
export default {
    data(){
        return {
			curtime:'',
			isloading:false,
            status:'0',
            keyword:'',
			managerid:'',
			curpage:1,
			pagesize:10,
			totalcount:null,
			tabledata:[
				{
					AgentName:"汕头市华音塑胶有限公司",
					AgentCode:'',
					Manager:'翁州',
					LegalPerson:'杜勃',
					Contact:'13825869184',
					Status:'1',
					ExpireDTime:'1654790400'
				},
				{
					AgentName:"桐乡木头人电子商务有限公司",
					AgentCode:'',
					Manager:'翁州',
					LegalPerson:'吴佳斌',
					Contact:'13819351567',
					Status:'1',
					ExpireDTime:'1654790400'
				},
				{
					AgentName:"洛阳酷贝儿童用品有限公司",
					AgentCode:'',
					Manager:'翁州',
					LegalPerson:'赵辉',
					Contact:'18037220090',
					Status:'1',
					ExpireDTime:'1652198400'
				},
				{
					AgentName:"安阳市樱洛阁服装有限公司",
					AgentCode:'',
					Manager:'张华',
					LegalPerson:'靳风明',
					Contact:'',
					Status:'1',
					ExpireDTime:'1646064000'
				},
				{
					AgentName:"湖南贝恩叮当猫婴童用品有限公司",
					AgentCode:'BEDDM01',
					Manager:'刘昭',
					LegalPerson:'聂康健',
					Contact:'15973100666',
					Status:'1',
					ExpireDTime:'1646150400'
				},
				{
					AgentName:"汕头市爱乐爱制衣实业有限公司",
					AgentCode:'',
					Manager:'刘昭',
					LegalPerson:'郑宝春',
					Contact:'13302732489',
					Status:'1',
					ExpireDTime:'1661875200'
				},
				{
					AgentName:"湖州唐潮服装设计有限公司",
					AgentCode:'tcfz',
					Manager:'翁州',
					LegalPerson:'',
					Contact:'',
					Status:'2',
					ExpireDTime:'1630339200'
				},
			]
        }
    },
    methods:{
        formatDate(date) {
			var datetime = new Date(date * 1000);
			var year = datetime.getFullYear();
			var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
			var day = ("0" + datetime.getDate()).slice(-2);
			return year + "-" + month + "-" + day;
		},
		AddAgent(){
			this.$router.push({path:'/customers/addagent'});
		},
		init(){
			this.isloading = true;
			this.axios.get(action.agentlist,{
				headers:{
					"content-type":"application/json"
				},
				params:{
					curpage:this.curpage,
					pagesize:this.pagesize,
					keyword:this.keyword,
					managerid:this.managerid,
					status:this.status,
				}
			}).then((response)=>{
				console.log(response.data);
				this.tabledata = response.data.list;
				this.curpage = response.data.curpage*1;
				this.totalcount = response.data.totalcount*1;
				this.isloading = false;
			});
		},
		handleSizeChange(val){
			this.pagesize = val;
			this.init();
		},
		handleCurrentChange(val){
			this.curpage = val;
			this.init();
		},
		Delete(id){
			this.$confirm("确定要删除该商户的信息么？","提示",{
				confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
			}).then(()=>{
				this.axios.get(action.delagent,{
					headers:{
						"content-type":"application/json"
					},
					params:{
						id:id
					}
				}).then((response)=>{
					console.log(response.data);
					if(response.data == "OK"){
						this.$message.success("操作成功");
						this.$router.go(0);
					}else{
						return false;
					}
				});
			});
		},
		Edit(id){
			this.$router.push({path:"/customers/addagent",query:{id:id,curpage:this.curpage}});
		}
    },
    beforeCreate() {
		this.PAGELANG = this.LANG.pages.Agentlist;
	},
	created:function(){
		this.init();
		this.curtime = Date.now();
		console.log(this.curtime);
	}
}
